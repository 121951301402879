import React from "react"
import Layout from "../../components/Layout"
import MathStarter from "../../components/mathstarter"
export default function TrackHero() {
  return (
    <>
      <Layout>
        <MathStarter />
      </Layout>
    </>
  )
}
