export const techTags = [
  {
    id: 1,
    name: "Product Design",
  },
  {
    id: 3,
    name: "Front-End",
  },
  {
    id: 4,
    name: "Back-End",
  },
  {
    id: 5,
    name: "DevOps",
  },
]

export const aboutSections = [
  {
    id: 1,
    heading: "Industry Vertical",
    description: "E-learning, Education, Ed-Tech",
  },
  {
    id: 2,
    heading: "Business Model",
    description: "B2B SaaS",
  },
  {
    id: 3,
    heading: "Our Work",
    description: "Product Design, Custom Software Development, DevOps",
  },
]

export const techImages = [
  {
    id: 1,
    icon: "/caseStudy/Mathstarter/tech1.svg",
  },
  {
    id: 2,
    icon: "/caseStudy/Mathstarter/tech2.svg",
  },
  {
    id: 3,
    icon: "/caseStudy/Mathstarter/tech3.svg",
  },
  {
    id: 4,
    icon: "/caseStudy/Mathstarter/tech4.svg",
  },
  {
    id: 5,
    icon: "/caseStudy/Mathstarter/tech5.svg",
  },
  {
    id: 6,
    icon: "/caseStudy/Mathstarter/tech6.svg",
  },
  {
    id: 7,
    icon: "/caseStudy/Mathstarter/tech7.svg",
  },
  {
    id: 8,
    icon: "/caseStudy/Mathstarter/tech8.svg",
  },
  {
    id: 9,
    icon: "/caseStudy/Mathstarter/tech9.svg",
  },
  {
    id: 10,
    icon: "/caseStudy/Mathstarter/tech10.svg",
  },
  {
    id: 11,
    icon: "/caseStudy/Mathstarter/tech11.svg",
  },
  {
    id: 12,
    icon: "/caseStudy/Mathstarter/tech12.svg",
  },
  {
    id: 13,
    icon: "/caseStudy/Mathstarter/tech13.svg",
  },
]

export const aboutProjectIcons = [
  { id: 1, icon: "/caseStudy/Mathstarter/about1.svg" },
  { id: 2, icon: "/caseStudy/Mathstarter/about2.svg" },
  { id: 3, icon: "/caseStudy/Mathstarter/about3.svg" },
]

export const keyChallenges = [
  {
    id: 1,
    title: "Inefficient-Equation Building",
    para: "The client needed a software solution for efficient equation-building but had limited knowledge of relevant technology and email verification procedures.",
  },
  {
    id: 2,
    title: "Educating The Client",
    para: "Despite several meetings to understand the client's requirements, significant time was spent educating the client.",
  },
  {
    id: 3,
    title: "Need for a Custom Library",
    para: "A library (MathQuill) was customized to address the equation-building challenge.",
  },
  {
    id: 4,
    title: "Cross-Browser Compatibility",
    para: "The client lacked awareness of cross-browser compatibility and required additional guidance on this subject.",
  },
]

export const solutions = [
  {
    id: 1,
    heading: "Empowering Clients",
    description:
      "We provided regular updates and training to ensure the client was always informed and empowered to make informed decisions.",
    icon: "/caseStudy/Mathstarter/solution1.svg",
  },
  {
    id: 2,
    heading: "Streamlined Equation Building",
    description:
      "Our solution leveraged the latest technology to provide a streamlined process for building equations, optimizing speed and efficiency.",
    icon: "/caseStudy/Mathstarter/solution2.svg",
  },
  {
    id: 3,
    heading: "Customized Libraries for Optimal Performance",
    description: `We customized MathQuill to meet the client's specific needs, resulting in optimal performance and a more efficient workflow.`,
    icon: "/caseStudy/Mathstarter/solution3.svg",
  },
  {
    id: 4,
    heading: "Seamless Cross-Browser Compatibility",
    description:
      "We worked closely with the client to ensure our solution was seamlessly integrated with all major browsers, providing additional guidance and support to ensure a clear understanding of this subject.",
    icon: "/caseStudy/Mathstarter/solution4.svg",
  },
]

export const featuresList = [
  {
    id: 1,
    feature: "Real Time Reports",
    icon: "/caseStudy/Mathstarter/feature1.svg",
    icon2: "/caseStudy/Mathstarter/feature11.svg",
    image: "/caseStudy/Mathstarter/featurebg1.png",
    paragraph:
      "Provides real-time reports for tracking student progress and performance.",
  },
  {
    id: 2,
    feature: "Student and School management",
    icon: "/caseStudy/Mathstarter/feature2.svg",
    icon2: "/caseStudy/Mathstarter/feature12.svg",
    image: "/caseStudy/Mathstarter/featurebg2.png",
    paragraph:
      "Includes powerful tools for managing student and school information, such as records, schedules, and attendance, all in one place.",
  },
  {
    id: 3,
    feature: "Quiz Builder",
    icon: "/caseStudy/Mathstarter/feature3.svg",
    icon2: "/caseStudy/Mathstarter/feature13.svg",
    image: "/caseStudy/Mathstarter/featurebg3.png",
    paragraph:
      "Includes a customizable quiz builder with a range of question types for creating and grading quizzes, making it easy to tailor assessments to specific needs.",
  },
  {
    id: 4,
    feature: "Students’ Report",
    icon: "/caseStudy/Mathstarter/feature4.svg",
    icon2: "/caseStudy/Mathstarter/feature14.svg",
    image: "/caseStudy/Mathstarter/featurebg4.png",
    paragraph:
      "Provides comprehensive student reports for tracking progress and identifying areas for improvement, with customizable templates for sharing with stakeholders.",
  },
]

export const resultsList = [
  {
    id: 1,
    heading: "Increased Efficiency",
    description: `Streamlined processes for managing student and school information, creating and grading quizzes, and generating student reports, resulting in increased efficiency.`,
    icon: "/caseStudy/Mathstarter/result1.svg",
  },
  {
    id: 2,
    heading: "Improved Student Performance",
    description: `Real-time reports and detailed student data to enable educators to identify areas where additional support may be needed, resulting in improved student performance.`,
    icon: "/caseStudy/Mathstarter/result2.svg",
  },
  {
    id: 3,
    heading: "Enhanced Learning Experience",
    description: `Customizable quiz builder and comprehensive student reports to enhance the learning experience by tailoring assessments to specific needs and providing valuable insights into student progress.`,
    icon: "/caseStudy/Mathstarter/result3.svg",
  },
  {
    id: 4,
    heading: "Increased Stakeholder Engagement",
    description: `Customizable student reports and a centralized database increased parent and stakeholder engagement by providing up-to-date information about student progress and school operations.`,
    icon: "/caseStudy/Mathstarter/result4.svg",
  },
  {
    id: 5,
    heading: "Scalability and Adaptability",
    description: `This software is scalable and adaptable, meeting the evolving needs of educational institutions of all sizes and types, resulting in increased adoption and user satisfaction.`,
    icon: "/caseStudy/Mathstarter/result5.svg",
  },
]

export const businessNeedsList = [
  {
    id: 1,
    heading: "Effective Educational Tool for New Zealand teachers",
    description: "",
    icon: "/caseStudy/Mathstarter/business1.svg",
  },
  {
    id: 2,
    heading: "Streamlined Administrative Processes",
    description: "",
    icon: "/caseStudy/Mathstarter/business2.svg",
  },
  {
    id: 3,
    heading: "Increased Parent and Stakeholder Engagement",
    description: "",
    icon: "/caseStudy/Mathstarter/business3.svg",
  },
  {
    id: 4,
    heading: "Enhanced Learning Experience",
    description: "",
    icon: "/caseStudy/Mathstarter/business4.svg",
  },
  {
    id: 5,
    heading: "Improved Student Outcomes",
    description: "",
    icon: "/caseStudy/Mathstarter/business5.svg",
  },
]
